import { FC } from "react";

const Header: FC<{ title: string; value?: string | number | null }> = ({
  title,
  value,
}) => (
  <div className="flex justify-between">
    <span className="font-medium text-2xl">{title}</span>
    <span className="font-medium text-2xl">{value}</span>
  </div>
);

export default Header;
