import { SearchOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { FC, PropsWithChildren } from "react";

const { Panel } = Collapse;

const SearchPanel: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Collapse accordion>
      <Panel
        showArrow={false}
        header={
          <div className="flex justify-start items-center gap-2">
            <SearchOutlined />
            <span className="text-3xl font-medium">Search</span>
          </div>
        }
        key="1"
      >
        {children}
      </Panel>
    </Collapse>
  );
};

export default SearchPanel;
