const Failed = () => (
  <div className="w-full text-center p-8">
    <i
      className="fa-solid fa-triangle-exclamation"
      style={{ fontSize: "2rem" }}
    ></i>
  </div>
);

export default Failed;
