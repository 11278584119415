import { useEffect, useState } from "react";
import useHeader from "../../hooks/use-header";
import { useTranslation } from "react-i18next";
import UserForm from "./components/user-form";

const UserCreate = () => {
  const { t } = useTranslation();
  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("User.Create User"));
    return () => {
      header.resetTitle();
    };
  }, [t]);

  return <UserForm />;
};

export default UserCreate;
