import apiClient from "../../apiClient";

const login = (data: ILogin) => apiClient.post<IToken>("/Auth/Login", data);

const renewToken = (refreshToken: string) =>
  apiClient.post<IToken>("/Auth/Refresh", { refreshToken });

export default {
  login,
  renewToken,
};
