import { FC } from "react";

const Item: FC<{ title: string; value?: string | number | null }> = ({
  title,
  value,
}) => (
  <div className="flex justify-between">
    <span className="font-normal text-xl">{title}</span>
    <span className="text-xl">{value}</span>
  </div>
);

export default Item;
