import { Method } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../apiClient";

interface IListParams {
  url: string;
  method: Method;
  params?: any;
  data?: any;
}

function useFetchData<T>(config: IListParams): {
  resource?: T;
  setResource: Dispatch<SetStateAction<T | undefined>>;
  fetching: boolean;
  failed: boolean;
} {
  const { url, method, params, data } = config;
  const [resource, setResource] = useState<T>();
  const [fetching, setFetching] = useState(true);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        setFetching(true);
        const res = await api.request<T>({ url, method, data });
        if (!ignore) {
          setResource(res.data);
        }
        setFailed(false);
      } catch (error) {
        setFailed(true);
        setResource(undefined);
      } finally {
        setFetching(false);
      }

      return () => {
        ignore = true;
      };
    };
    fetchData();
  }, [url, method, params, data]);
  return { resource, setResource, fetching, failed };
}

export default useFetchData;
