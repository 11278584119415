import { Button, Empty } from "antd";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../apiClient";
import footerLogo from "../../assets/epay-footer-logo.png";
import { DEFAULT_CURRENCY, END_POINTS } from "../../common/constants";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import LanguageToggle from "../../components/languageToggle";
import Seperate from "../../components/seperate";
import Header from "../../components/transaction/header";
import Item from "../../components/transaction/input";
import useFetchData from "../../hooks/use-fetch-data";
import useLoading from "../../hooks/use-loading";
import { convertBooleanEnv } from "../../utils/helper";

interface IDetail {
  label: string;
  value?: string | number | null;
}

const { REACT_APP_PAYMENT_LANGUAGE_TOGGLE } = process.env;
const showLangToggle = convertBooleanEnv(REACT_APP_PAYMENT_LANGUAGE_TOGGLE);

const PaySummary = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const loading = useLoading();
  const {
    resource: summary,
    fetching,
    failed,
  } = useFetchData<IPaySummary>({
    url: `${END_POINTS.PAYMENT.SUMMARY}${code}`,
    method: "get",
  });

  const lang = summary?.language;

  useEffect(() => {
    if (!lang) return;
    i18n.changeLanguage(lang);
  }, [lang]);

  const pay = () => {
    if (!code) return;
    const executePayment = async () => {
      try {
        loading.show();
        const res = await api.get<any>(`${END_POINTS.PAYMENT.PAY}${code}`);
        const newWindow = window.open(undefined, "_self");
        newWindow?.document.write(res.data);
        newWindow?.document.close();
      } catch (error) {
      } finally {
        loading.hide();
      }
    };
    executePayment();
  };

  if (fetching) return <Loading />;
  if (failed) return <Failed />;
  if (!summary) return <Empty />;

  const {
    color1,
    color2,
    logo,
    title,
    nidNipt,
    idTransaksioni,
    gjeneraliteteTePersonit,
    pershkrimeTeSherbimit,
    detajeTeTjera,
    currencyCode,
    originalAmount,
    paymentFee,
    paymentCurrencyCode,
    exchangeRate,
    vleraSherbimitNeLeke,
  } = summary;

  let personalData: IOption[] = [];
  try {
    const parsed = JSON.parse(gjeneraliteteTePersonit);
    personalData = parsed;
  } catch (error) {}

  let serviceDetail: IOption[] = [];
  try {
    const parsed = JSON.parse(pershkrimeTeSherbimit);
    serviceDetail = parsed;
  } catch (error) {}

  let serviceOtherDetail: IDetail[] = [];
  try {
    const parsed: IDetail[] = JSON.parse(detajeTeTjera);
    serviceOtherDetail = parsed.filter(
      s => ![null, undefined, ""].some(i => i === s.value)
    );
  } catch (error) {}

  return (
    <div className="min-h-screen bg-neutral-100">
      <div
        className={`flex justify-center p-4`}
        style={{ backgroundColor: color1 as string }}
      >
        <img
          src={logo as string}
          alt=""
          className="w-1/5 max-w-[72px] cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div
        className={`py-2`}
        style={{ backgroundColor: color2 as string }}
      >
        <span className="font-medium text-2xl text-white">
          {t("paySummary.paymentSummary")}
        </span>
      </div>

      {showLangToggle && (
        <div className="p-4">
          <LanguageToggle className="bg-gray-400" />
        </div>
      )}

      <div className="w-full flex flex-col items-center">
        <div className="w-11/12 max-w-screen-xl px-6 py-4 my-8 bg-white">
          <Header
            title={t("paySummary.service")}
            value={title}
          />
          <Item
            title={t("paySummary.vatNumber")}
            value={nidNipt}
          />
          <Item
            title={t("paySummary.invoiceRef")}
            value={idTransaksioni}
          />
          <Seperate />

          <Header title={t("paySummary.personalData")} />
          {personalData.map((p, idx) => (
            <Item
              key={idx}
              title={t(`paySummary.${p.label}`) || p.label}
              value={p.value}
            />
          ))}
          <Seperate />

          <Header title={t("paySummary.serviceDetails")} />
          {serviceDetail.map((s, idx) => (
            <Item
              key={idx}
              title={t(`paySummary.${s.label}`) || s.label}
              value={s.value}
            />
          ))}
          <Seperate />

          {serviceOtherDetail.length > 0 && (
            <>
              <Header title={t("paySummary.serviceOtherDetails")} />
              {serviceOtherDetail.map((s, idx) => (
                <Item
                  key={idx}
                  title={t(`paySummary.${s.label}`) || s.label}
                  value={s.value}
                />
              ))}
              <Seperate />
            </>
          )}

          <Header
            title={t("paySummary.serviceAmount")}
            value={`${originalAmount} ${currencyCode}`}
          />
          <Seperate />

          <Header
            title={t("paySummary.paymentFee")}
            value={`${paymentFee} ${paymentCurrencyCode}`}
          />
          <Seperate />

          {currencyCode &&
            currencyCode.toLowerCase() !== DEFAULT_CURRENCY.toLowerCase() && (
              <>
                <Header
                  title={t("paySummary.exchangeRate")}
                  value={exchangeRate}
                />
                <Seperate />
              </>
            )}

          <Header
            title={t("paySummary.totalAmount")}
            value={`${vleraSherbimitNeLeke} ${paymentCurrencyCode}`}
          />
          <Seperate />

          <Button
            type="primary"
            onClick={pay}
            className="bg-[#1890ff] mt-8"
          >
            {t("paySummary.processWithPayment")}
          </Button>
        </div>
        <div className="py-4 flex justify-center mt-8">
          <img
            src={footerLogo as string}
            alt="Logo"
            className="h-24"
          />
        </div>
      </div>
    </div>
  );
};

export default PaySummary;
