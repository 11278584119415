import { Button, Form, Input, Typography } from "antd";
import jwt_decode from "jwt-decode";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  END_POINTS,
  PENDING_USERNAME,
  REFRESH_TOKEN,
  USER,
} from "../../common/constants";
import { DispatchContext, IActionType } from "../../contexts";
import useLoading from "../../hooks/use-loading";
import { mutate } from "../../utils/helper";

const { Title } = Typography;
const { Password } = Input;

const rules = {
  userName: [{ required: true, message: "Username is required" }],
  password: [{ required: true, message: "Password is required" }],
};

const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ILogin>();
  const [showPw, setShowPw] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useContext(DispatchContext);

  const loading = useLoading();

  const onFinish = async (values: ILogin) => {
    loading.show();
    mutate<IToken>(
      {
        config: {
          url: END_POINTS.LOGIN,
          method: "post",
          data: values,
        },
        onSuccess: response => {
          const { forceChangePassword, token, refreshToken } = response;
          if (forceChangePassword) {
            localStorage.setItem(PENDING_USERNAME, values.userName);
            navigate("/change-password");
            return;
          }
          if (!token || !refreshToken) return;
          var decoded: IDecodedToken = jwt_decode<IDecodedToken>(token);
          const { FullName, DbName } = decoded;
          const user: IClient = {
            fullName: FullName,
            dbName: DbName,
          };

          dispatch({
            type: IActionType.SET_USER,
            payload: { user },
          });

          localStorage.setItem(USER, JSON.stringify(user));
          localStorage.setItem(ACCESS_TOKEN, token);
          localStorage.setItem(REFRESH_TOKEN, refreshToken);
        },
        onFinish: () => {
          loading.hide();
        },
      },
      { hideSuccessAlert: true }
    );
  };
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="w-1/8 min-w-min max-w-lg">
        <Title>
          <i
            className="fa-solid fa-money-check-dollar"
            style={{ marginRight: 10 }}
          ></i>
          Credins ePay
        </Title>
        <Form
          form={form}
          onFinish={onFinish}
          labelWrap
        >
          <Form.Item
            name="userName"
            rules={rules.userName}
          >
            <Input
              size="large"
              placeholder={t("Username") as string}
              prefix={<i className="fa-solid fa-user"></i>}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={rules.userName}
          >
            <Password
              size="large"
              placeholder={t("Password") as string}
              prefix={<i className="fa-solid fa-key"></i>}
              visibilityToggle={{
                visible: showPw,
                onVisibleChange: setShowPw,
              }}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="w-full bg-[#1890ff]"
          >
            {t("Login")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
