import {
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { END_POINTS } from "../../../common/constants";
import BackButton from "../../../components/buttons/back";
import SaveButton from "../../../components/buttons/save";
import useFetchData from "../../../hooks/use-fetch-data";
import useHeader from "../../../hooks/use-header";
import useLoading from "../../../hooks/use-loading";
import i18n from "../../../i18n";
import { mutate } from "../../../utils/helper";

const { Item } = Form;
const LIST_SERVICE_PAGE = "/service";

interface IServiceUserForm {
  isDelete: boolean;
  isUpdate: boolean;
  serviceId: number;
  userid: number;
  id: number;
}

const ServiceForm: FC<{
  init?: IService;
  serviceUsers?: IServiceUser[];
}> = ({ init, serviceUsers }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm<IService>();
  const navigate = useNavigate();

  const [edittingServiceUsers, setEdittingServiceUsers] = useState(
    serviceUsers || []
  );
  const [prevServiceUsers, setPrevServiceUsers] = useState(serviceUsers);

  if (serviceUsers && prevServiceUsers !== serviceUsers) {
    setPrevServiceUsers(serviceUsers);
    setEdittingServiceUsers(serviceUsers);
  }

  const header = useHeader();
  const loading = useLoading();

  let formInit: IService | undefined = init && { ...init };

  const { resource: users, fetching } = useFetchData<IUser[]>({
    url: END_POINTS.USER.LIST,
    method: "get",
  });

  const onFinish = (values: IService) => {
    if (noUserChosen) return;
    const submitValues: IService = {
      ...values,
      bypasEpay: !!values.bypasEpay,
      id: id && parseInt(id) && init ? parseInt(id) : 0,
      usrUserService: [],
    };

    const submitServiceUsers = serviceUsers?.map(su => ({
      ...su,
      isDelete: !edittingServiceUsers?.find(c => c.userid === su.userid),
      isUpdate: false,
    }));

    const newServiceUsers = edittingServiceUsers
      ?.filter(c => !serviceUsers?.find(su => su.userid === c.userid))
      .map(c => ({
        id: 0,
        serviceId: parseInt(id as string),
        userid: c.userid,
        isDelete: false,
        isUpdate: false,
      }));

    let userServices: IServiceUserForm[] = [];
    if (submitServiceUsers)
      userServices = [...userServices, ...submitServiceUsers];
    if (newServiceUsers) userServices = [...userServices, ...newServiceUsers];

    loading.show();
    mutate({
      config: {
        url: END_POINTS.SERVICE.MODIFY,
        method: "post",
        data: { ...submitValues, usrUserService: userServices },
      },
      onSuccess: () => {
        navigate(LIST_SERVICE_PAGE);
      },
      onFinish: () => {
        loading.hide();
      },
    });
  };

  useEffect(() => {
    const extra = (
      <Space size="small">
        <BackButton
          onClick={() => {
            navigate(LIST_SERVICE_PAGE);
          }}
        />
        <SaveButton onClick={() => form.submit()} />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [id, form, header, navigate]);

  const accessibilityTableRecords = users?.map(u => ({
    key: u.user_Id,
    userId: u.user_Id,
    userName: u.fullname,
    isAccessible: !!edittingServiceUsers?.some(eu => eu.userid === u.user_Id),
  }));

  const SERVICE_USER_COLUMNS: ColumnsType<{
    userId: number;
    userName: string;
    isAccessible: boolean;
  }> = [
    {
      title: t("Username"),
      dataIndex: "userName",
      key: "userName",
      width: "80%",
    },
    {
      title: t("Service.Access"),
      dataIndex: "isAccessible",
      key: "isAccessible",
      width: "20%",
      render: (value, record) => (
        <Checkbox
          checked={!!value}
          onChange={e => {
            const { userId } = record;
            const filtered = edittingServiceUsers?.filter(
              eu => eu.userid !== userId
            );
            if (e.target.checked) {
              filtered?.push({
                id: 0,
                userid: userId,
                serviceId: parseInt(id as string),
              });
            }
            setEdittingServiceUsers(filtered);
          }}
        />
      ),
    },
  ];

  const noUserChosen =
    accessibilityTableRecords &&
    accessibilityTableRecords.every(r => !r.isAccessible);

  const rules: IRules = {
    title: [
      { required: true, message: t("Service.Title is required") as string },
    ],
    shopId: [
      { required: true, message: t("Service.Shop ID is required") as string },
    ],
    merchantid: [
      {
        required: true,
        message: t("Service.Merchant ID is required") as string,
      },
    ],
    merchantpsw: [
      {
        required: true,
        message: t("Service.Merchant PSW is required") as string,
      },
    ],
    accountingMode: [
      {
        required: true,
        message: t("Service.Accounting Mode is required") as string,
      },
    ],
    authorMode: [
      {
        required: true,
        message: t("Service.Author Mode is required") as string,
      },
    ],
    options: [
      { required: true, message: t("Service.Options is required") as string },
    ],
    keyStart: [
      { required: true, message: t("Service.KeyStart is required") as string },
    ],
    keyResult: [
      { required: true, message: t("Service.KeyResult is required") as string },
    ],
  };

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce(
        (arr: NamePath[], field) => (
          field.errors.length && arr.push(field.name), arr
        ),
        []
      );

    form.validateFields(errorFields);
  }, [i18n.language, form]);

  return (
    <Form
      form={form}
      initialValues={formInit}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      labelWrap
    >
      <div className="lg:flex lg:gap-[16%]">
        <div className="lg:flex-1">
          <Item
            name="title"
            label={t("Title")}
            rules={rules.title}
          >
            <Input />
          </Item>
          <Item
            name="minimumFee"
            label={t("Service.Minimum Fee")}
          >
            <InputNumber className="w-full" />
          </Item>
          <Item
            name="absoluteFee"
            label={t("Service.Absolute Fee")}
          >
            <InputNumber className="w-full" />
          </Item>
          <Item
            name="paymentFee"
            label={t("Service.Payment Fee")}
          >
            <InputNumber className="w-full" />
          </Item>
          <Item
            name="roundUp"
            label={t("Service.Round Up")}
          >
            <InputNumber className="w-full" />
          </Item>
          <Item
            name="merchantid"
            label={t("Service.Merchantid")}
            rules={rules.merchantid}
          >
            <Input />
          </Item>
          <Item
            name="merchantpsw"
            label={t("Service.Merchant PSW")}
            rules={rules.merchantpsw}
          >
            <Input />
          </Item>
        </div>
        <div className="lg:flex-1">
          <Item
            name="bypasEpay"
            label={t("Service.Bypas Epay")}
            valuePropName="checked"
          >
            <Checkbox />
          </Item>
          <Item
            name="shopId"
            label={t("Service.ShopID")}
            rules={rules.shopId}
          >
            <Input />
          </Item>
          <Item
            name="accountingmode"
            label={t("Service.Accounting Mode")}
            rules={rules.accountingMode}
          >
            <Input />
          </Item>
          <Item
            name="authormode"
            label={t("Service.Author Mode")}
            rules={rules.authorMode}
          >
            <Input />
          </Item>
          <Item
            name="options"
            label={t("Options")}
            rules={rules.options}
          >
            <Input />
          </Item>
          <Item
            name="keyStart"
            label={t("Service.Key Start")}
            rules={rules.keyStart}
          >
            <Input />
          </Item>
          <Item
            name="keyResult"
            label={t("Service.Key Result")}
            rules={rules.keyResult}
          >
            <Input />
          </Item>
        </div>
      </div>
      <Divider />
      <div className="text-left mb-2">
        <span className={noUserChosen ? "text-red-500" : "text-white"}>
          {t("Service.At least one user need to be chosen")}
        </span>
      </div>

      <Table
        bordered
        loading={fetching}
        columns={SERVICE_USER_COLUMNS}
        dataSource={accessibilityTableRecords}
        pagination={false}
      />
    </Form>
  );
};

export default ServiceForm;
