import { EyeOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import AppButton from ".";

const ViewButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { t } = useTranslation();

  return (
    <AppButton {...props}>
      <EyeOutlined /> {props.children || t("View")}
    </AppButton>
  );
};

export default ViewButton;
