import { ReactNode, useContext, useMemo } from "react";
import { DispatchContext, IActionType } from "../contexts";
import { APP_HEADER } from "../common/constants";

const useHeader = () => {
  const dispatch = useContext(DispatchContext);
  return useMemo(
    () => ({
      setTitle: (title: string) => {
        dispatch({
          type: IActionType.SET_HEADER_TITLE,
          payload: {
            headerTitle: title,
          },
        });
      },
      resetTitle: () => {
        dispatch({
          type: IActionType.SET_HEADER_TITLE,
          payload: {
            headerTitle: APP_HEADER,
          },
        });
      },
      setExtra: (node: ReactNode) => {
        dispatch({
          type: IActionType.SET_HEADER_EXTRA,
          payload: {
            headerExtra: node,
          },
        });
      },
      clearExtra: () => {
        dispatch({
          type: IActionType.SET_HEADER_EXTRA,
          payload: {
            headerExtra: null,
          },
        });
      },
    }),
    [dispatch]
  );
};

export default useHeader;
