export const APP_HEADER = "Credins ePay";

export const ALERT_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const USER = "USER";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const PENDING_USERNAME = "PENDING_USERNAME";

export const SITE_INFO = "SITE_INFO";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "MMMM Do YYYY, h:mm:ss a";

export const MAX_SERVER_INT = 2147483647;

export const MENU_ITEM_COLORS = [
  "error.main",
  "warning.light",
  "primary.main",
  "success.main",
  "primary.light",
  "grey.700",
];

export type LANGUAGES = "al" | "en";
export const languages: { [T in LANGUAGES]: T } = {
  al: "al",
  en: "en",
};
export enum UserRole {
  Admin = 1,
  Client = 2,
}
export const LANGUAGE = "LANGUAGE";

export const DEFAULT_SEARCH_PARAMS = {
  pageNumber: 1,
  pageSize: 50,
};

export const PAGE_SIZE_OPTIONS = [50, 100, 200, 500];

export const SIDE_MENU_WIDTH = {
  COLLAPSE: 64,
  EXTENDED: 260,
};

export const END_POINTS = {
  LOGIN: "/Auth/Login",
  REFRESH: "/Auth/Refresh",
  SIDE_MENU: "/GenModule/SiteMenus",
  USER: {
    LIST: "/User/GetAll",
    MODIFY: "/User/Edit",
    DETAIL: "/User/",
    SETTINGS: "/User/Settings",
    CHANGE_PASSWORD: "/User/ChangePass",
  },
  CURRENCY: {
    LIST: "/Currency/GetGenCurrencies",
  },
  CURRENCY_RATE: {
    LIST: "/Currency/GetGenCurrencyRate",
    DETAIL: "/Currency/Rate/",
    MODIFY: "/Currency/EditCurrencyRate",
  },
  PAYMENT: {
    SUMMARY: "/transaction/code/",
    PAY: "/transaction/pay/",
  },
  SERVICE: {
    LIST: "/Service",
    DETAIL: "/Service/",
    GET_USERS: (id: string | number) => `/Service/${id}/Users`,
    MODIFY: "/Service/Edit",
  },
  TRANSACTIONS: {
    LIST: "/Transaction/GetTransactionsByCriteriaWithPagination",
    DETAIL: "/Transaction/",
    HAS_ACCESS: "/Transaction/HasAccess",
    GET_TRANSACTION_EXPORT: "/Transaction/GetTransactionsExport"
  },
};

export const DEFAULT_CURRENCY = "ALL";

export const DATE_QUERY_FORMAT = "YYYY-MM-DD";
