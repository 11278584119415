import { Dispatch, SetStateAction, useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../common/constants";

function useList<F>(inputs?: any): {
  selectedKey?: number;
  setSelectedKey: Dispatch<SetStateAction<number | undefined>>;
  filters?: any;
  setFilters: Dispatch<SetStateAction<any>>;
} {
  const [selectedKey, setSelectedKey] = useState<number>();
  const [filters, setFilters] = useState<any>(inputs || DEFAULT_SEARCH_PARAMS);
  return { selectedKey, setSelectedKey, filters, setFilters };
}

export default useList;
