import { Checkbox, Empty, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { END_POINTS } from "../../common/constants";
import CreateButton from "../../components/buttons/create";
import EditButton from "../../components/buttons/edit";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import useList from "../../hooks/use-list";

const CREATE_SERVICE_PAGE = "/service/create";
const EDIT_SERVICE_PAGE = "/service/edit";

const ServiceList = () => {
  const navigate = useNavigate();
  const header = useHeader();
  const { t } = useTranslation();
  const {
    resource: services,
    fetching,
    failed,
  } = useFetchData<IService[]>({
    url: END_POINTS.SERVICE.LIST,
    method: "get",
  });
  const { selectedKey, setSelectedKey } = useList();

  useEffect(() => {
    header.setTitle(t("Service.Service"));

    return () => {
      header.resetTitle();
    };
  }, [t]);

  useEffect(() => {
    const extra = (
      <Space size="small">
        <CreateButton
          onClick={() => {
            navigate(CREATE_SERVICE_PAGE);
          }}
        />
        <EditButton
          disabled={!selectedKey}
          onClick={() => {
            selectedKey && navigate(`${EDIT_SERVICE_PAGE}/${selectedKey}`);
          }}
        />
      </Space>
    );

    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [selectedKey]);

  const SERVICE_COLUMNS: ColumnsType<IService> = [
    {
      title: t("Service.Id"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Service.Title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("Service.Minimum Fee"),
      dataIndex: "minimumFee",
      key: "minimumFee",
    },
    {
      title: t("Service.Absolute Fee"),
      dataIndex: "absoluteFee",
      key: "absoluteFee",
    },
    {
      title: t("Service.Payment Fee"),
      dataIndex: "paymentFee",
      key: "paymentFee",
    },
    {
      title: t("Service.Round Up"),
      dataIndex: "roundUp",
      key: "roundUp",
    },
    {
      title: t("Service.Shop Id"),
      dataIndex: "shopId",
      key: "shopId",
    },
    {
      title: t("Service.Accounting Mode"),
      dataIndex: "accountingmode",
      key: "accountingmode",
    },
    {
      title: t("Service.Author Mode"),
      dataIndex: "authormode",
      key: "authormode",
    },
    {
      title: t("Service.Options"),
      dataIndex: "options",
      key: "options",
    },
    {
      title: t("Service.Bypas Epay"),
      dataIndex: "bypasEpay",
      key: "bypasEpay",
      render: value => (
        <Checkbox
          checked={value}
          disabled
        />
      ),
    },
  ];

  if (fetching) return <Loading />;
  if (failed) return <Failed />;
  const tableSource = services?.map(u => ({ ...u, key: u.id }));
  return (
    <Table
      bordered
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: IService[]) => {
          setSelectedKey(selectedRowKeys[0] as number);
        },
      }}
      columns={SERVICE_COLUMNS}
      dataSource={tableSource}
      pagination={false}
    />
  );
};

export default ServiceList;
