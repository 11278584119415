import { Checkbox, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { END_POINTS } from "../../common/constants";
import CreateButton from "../../components/buttons/create";
import EditButton from "../../components/buttons/edit";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import useList from "../../hooks/use-list";

const CREATE_USER_PAGE = "/user/create";
const EDIT_USER_PAGE = "/user/edit";

const UserList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    resource: users,
    fetching,
    failed,
  } = useFetchData<IUser[]>({
    url: END_POINTS.USER.LIST,
    method: "get",
  });
  const { selectedKey, setSelectedKey } = useList();
  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("User.User"));
    return () => {
      header.resetTitle();
    };
  }, [t, header]);

  useEffect(() => {
    const extra = (
      <Space size="small">
        <CreateButton
          onClick={() => {
            navigate(CREATE_USER_PAGE);
          }}
        />
        <EditButton
          disabled={!selectedKey}
          onClick={() => {
            selectedKey && navigate(`${EDIT_USER_PAGE}/${selectedKey}`);
          }}
        />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [selectedKey, t, header, navigate]);

  const COLUMNS: ColumnsType<IUser> = [
    {
      title: t("User.User Name"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("User.Full Name"),
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: t("Active"),
      dataIndex: "active_user",
      key: "active_user",
      render: value => (
        <Checkbox
          checked={value}
          disabled
        />
      ),
    },
  ];

  const tableSource = users?.map(u => ({ ...u, key: u.user_Id }));
  return (
    <Table
      bordered
      loading={fetching}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: IUser[]) => {
          setSelectedKey(selectedRowKeys[0] as number);
        },
      }}
      columns={COLUMNS}
      dataSource={failed ? [] : tableSource}
      pagination={false}
    />
  );
};

export default UserList;
