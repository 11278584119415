import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import al from "./locales/al/translation.json";
import { LANGUAGE, languages } from "./common/constants";

const resources = {
  en: {
    translation: en,
  },
  al: {
    translation: al,
  },
};

const savedLanguage = localStorage.getItem(LANGUAGE);
const selectedLang = savedLanguage || languages.al;

if (savedLanguage !== selectedLang)
  localStorage.setItem(LANGUAGE, selectedLang);

i18n.use(initReactI18next).init({
  resources,
  lng: selectedLang,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
