import { useTranslation } from "react-i18next";
import { END_POINTS } from "../../../common/constants";
import useFetchData from "../../../hooks/use-fetch-data";

const useSiderNavigation = () => {
  const {
    resource: items,
    fetching,
    failed,
  } = useFetchData<IMenuItem[]>({
    url: END_POINTS.SIDE_MENU,
    method: "get",
  });
  const { t } = useTranslation();

  const transformMenuItem: any = (item: IMenuItem) => {
    const { key, label, icon, children, isDetailed } = item;
    return {
      key,
      label: t(label),
      icon: (
        <i
          className={icon}
          style={{ marginRight: 1 }}
        />
      ),
      children:
        isDetailed && children && children.length > 0
          ? children.map((c: IMenuItem) => transformMenuItem(c))
          : null,
    };
  };

  const formattedItems = items?.map(i => transformMenuItem(i));

  return {
    items: formattedItems,
    fetching,
    failed,
  };
};

export default useSiderNavigation;
