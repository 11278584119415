import { Spin } from "antd";
import { useContext } from "react";
import { AppContext } from "../../contexts";

const LoadingScreen = () => {
  const store = useContext(AppContext);
  const { loading } = store;

  return loading ? (
    <div className="relative">
      <div className="fixed w-screen h-screen top-0 left-0 flex justify-center items-center z-[9999] bg-black opacity-70">
        <Spin size="large" />
      </div>
    </div>
  ) : null;
};

export default LoadingScreen;
