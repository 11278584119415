import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useHeader from "../../hooks/use-header";
import CurrencyRateForm from "./components/currency-rate-form";

const CreateCurrency = () => {
  const { t } = useTranslation();
  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("Create Currency Rate"));

    return () => {
      header.resetTitle();
    };
  }, [t, header]);

  return <CurrencyRateForm />;
};

export default CreateCurrency;
