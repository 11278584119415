import ChangePasswordForm from "../../components/change-password-form";

const ChangePassword = () => (
  <div className="flex justify-center">
    <div className="w-1/8 min-w-min max-w-xl">
      <ChangePasswordForm />
    </div>
  </div>
);

export default ChangePassword;
