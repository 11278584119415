import { Checkbox, Empty, Form, Input, InputNumber, Space, Select } from "antd";
import { useEffect } from "react";
import { Rule } from "antd/lib/form";
import { useNavigate, useParams } from "react-router";
import { END_POINTS } from "../../common/constants";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import useLoading from "../../hooks/use-loading";
import { mutate } from "../../utils/helper";
import BackButton from "../../components/buttons/back";
import { useTranslation } from "react-i18next";
import SaveButton from "../../components/buttons/save";

const { Item, useWatch } = Form;

const UserSettings = () => {
  const { id } = useParams();
  const [form] = Form.useForm<IUserSettings>();
  const {
    resource: userSettings,
    fetching,
    failed,
  } = useFetchData<IUserSettings>({
    url: `${END_POINTS.USER.SETTINGS}`,
    method: "get",
  });

  const header = useHeader();
  const loading = useLoading();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    header.setTitle(t("User.Settings"));
    return () => {
      header.resetTitle();
    };
  }, [t]);

  useEffect(() => {
    const extra = (
      <Space size="small">
        <BackButton
          onClick={() => {
            navigate("/");
          }}
        />
        <SaveButton onClick={() => form.submit()} />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [id]);

  const color1 = useWatch("color1", form);
  const color2 = useWatch("color2", form);

  if (fetching) return <Loading />;
  if (failed) return <Failed />;
  if (!userSettings) return <Empty />;

  const onFinish = (values: IUserSettings) => {
    const submitValues = {
      ...values,
    };
    loading.show();
    mutate({
      config: {
        url: END_POINTS.USER.SETTINGS,
        method: "put",
        data: submitValues,
      },
      onSuccess: () => {
        navigate("/");
      },
      onFinish: () => {
        loading.hide();
      },
    });
  };

  return (
    <Form
      form={form}
      initialValues={userSettings}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      labelWrap
    >
      <div className="lg:flex lg:gap-[16%]">
        <div className="lg:flex-1">
          <Item
            name="iconPath"
            label={t("User.Logo")}
          >
            <Input />
          </Item>
          <Item label={t("User.Color 1")}>
            <Space>
              <Item
                name="color1"
                noStyle
              >
                <Input
                  className="w-[30px] !h-[30px] border-none outline-[rgba(24,144,255,.2)] outline-[2px] outline box-shadow-none p-0 bg-transparent"
                  type="color"
                />
              </Item>
              {color1 ? <p>{color1}</p> : <i>Please select color</i>}
            </Space>
          </Item>
          <Item label={t("User.Color 2")}>
            <Space>
              <Item
                name="color2"
                noStyle
              >
                <Input
                  className="w-[30px] !h-[30px] border-none outline-[rgba(24,144,255,.2)] outline-[2px] outline box-shadow-none p-0 bg-transparent"
                  type="color"
                />
              </Item>
              {color2 ? <p>{color2}</p> : <i>Please select color</i>}
            </Space>
          </Item>
        </div>
        <div className="lg:flex-1"></div>
      </div>
    </Form>
  );
};

export default UserSettings;
