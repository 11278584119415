import { DatePicker, DatePickerProps, Form, Select, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  DATE_FORMAT,
  DATE_QUERY_FORMAT,
  END_POINTS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import ClearButton from "../../components/buttons/clear";
import CreateButton from "../../components/buttons/create";
import EditButton from "../../components/buttons/edit";
import SearchButton from "../../components/buttons/search";
import SearchPanel from "../../components/search-panel";
import Seperate from "../../components/seperate";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import useList from "../../hooks/use-list";

const CREATE_CURRENCY_RATE_PAGE = "/currency-rate/create";
const EDIT_CURRENCY_RATE_PAGE = "/currency-rate/edit";
const pageSizeOptions = [50, 100, 200];

interface ICurrencyRateQuery {
  currencyId?: number;
  toCurrencyId?: number;
  validityDateFrom?: string;
  validityDateTo?: string;
  isValidityDateBetweenUsed?: boolean;
}

const { Item } = Form;

const CurrencyRateList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedKey, setSelectedKey, filters, setFilters } =
    useList<ICurrencyRateQuery>();

  const {
    resource: pagination,
    fetching,
    failed,
  } = useFetchData<IPaginationResponse<ICurrencyRate>>({
    url: END_POINTS.CURRENCY_RATE.LIST,
    method: "post",
    data: filters,
  });

  const { resource: currencies, fetching: fetchingCurrency } = useFetchData<
    ICurrency[]
  >({
    url: END_POINTS.CURRENCY.LIST,
    method: "get",
  });

  const [panelFilters, setPanelFilter] = useState(filters);

  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("Currency Rates"));

    return () => {
      header.resetTitle();
    };
  }, [t]);

  useEffect(() => {
    const extra = (
      <Space size="small">
        <CreateButton
          onClick={() => {
            navigate(CREATE_CURRENCY_RATE_PAGE);
          }}
        />
        <EditButton
          disabled={!selectedKey}
          onClick={() => {
            selectedKey &&
              navigate(`${EDIT_CURRENCY_RATE_PAGE}/${selectedKey}`);
          }}
        />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [selectedKey]);

  const currencyOptions: IOption[] = currencies
    ? currencies.map(c => ({ label: c.currencyCode, value: c.currencyId }))
    : [];

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    const filterStartDate = date
      ? moment(date).format(DATE_QUERY_FORMAT)
      : undefined;
    const isValidityDateBetweenUsed =
      !!filterStartDate && !!panelFilters?.validityDateTo;
    setPanelFilter((f: any) => ({
      ...f,
      validityDateFrom: filterStartDate,
      isValidityDateBetweenUsed,
    }));
  };

  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    const filterEndDate = date
      ? moment(date).format(DATE_QUERY_FORMAT)
      : undefined;
    const isValidityDateBetweenUsed =
      !!filterEndDate && !!panelFilters?.validityDateFrom;
    setPanelFilter((f: any) => ({
      ...f,
      validityDateTo: filterEndDate,
      isValidityDateBetweenUsed,
    }));
  };

  const COLUMNS: ColumnsType<ICurrencyRate> = [
    {
      title: t("Currency.From Currency"),
      dataIndex: "currencyCode",
      key: "currencyCode",
    },
    {
      title: t("Currency.To Currency"),
      dataIndex: "toCurrencyCode",
      key: "toCurrencyCode",
    },
    {
      title: t("Currency.Rate"),
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: t("Currency.Validity Date"),
      dataIndex: "validityDate",
      key: "validityDate",
      render: value => moment(value).format(DATE_FORMAT),
    },
  ];

  const tableSource = pagination?.items.map(r => ({ ...r, key: r.id }));

  const performSearch = () => {
    setFilters(panelFilters);
  };

  const clearFilters = () => {
    const newFilters = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber,
    };
    setPanelFilter(newFilters);
    setFilters(newFilters);
  };

  return (
    <>
      <SearchPanel>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onKeyDown={e => {
            if (e.key === "Enter") performSearch();
          }}
          labelWrap
        >
          <div className="lg:flex lg:gap-[4%]">
            <div className="lg:flex-1">
              <Item
                label={t("Currency.From Currency")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  className="w-full"
                  placeholder={t("Currency.Select currency")}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={fetchingCurrency}
                  options={currencyOptions}
                  value={panelFilters.currencyId}
                  onChange={value => {
                    setPanelFilter((f: any) => ({
                      ...f,
                      currencyId: value || undefined,
                    }));
                  }}
                />
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Currency.To Currency")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  className="w-full"
                  placeholder={t("Currency.Select currency")}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={fetchingCurrency}
                  options={currencyOptions}
                  value={panelFilters.toCurrencyId}
                  onChange={value => {
                    setPanelFilter((f: any) => ({
                      ...f,
                      toCurrencyId: value || undefined,
                    }));
                  }}
                />
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("From Date")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  value={
                    panelFilters?.validityDateFrom
                      ? moment(panelFilters.validityDateFrom)
                      : null
                  }
                  onChange={onChangeStartDate}
                  className="w-full"
                  placeholder={t("Select date") as string}
                />
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("To Date")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  value={
                    panelFilters?.validityDateTo
                      ? moment(panelFilters.validityDateTo)
                      : null
                  }
                  onChange={onChangeEndDate}
                  className="w-full"
                  placeholder={t("Select date") as string}
                />
              </Item>
            </div>
          </div>
        </Form>
        <Seperate />
        <div className="flex gap-2 justify-end mt-6">
          <ClearButton onClick={clearFilters}>{t("Clear filters")}</ClearButton>
          <SearchButton onClick={performSearch}>{t("Submit")}</SearchButton>
        </div>
      </SearchPanel>
      <br />
      <Table
        bordered
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ICurrencyRate[]
          ) => {
            setSelectedKey(selectedRowKeys[0] as number);
          },
        }}
        loading={fetching}
        columns={COLUMNS}
        dataSource={tableSource}
        pagination={{
          locale: {
            items_per_page: `/ ${t("Page")}`,
          },
          showSizeChanger: true,
          pageSizeOptions: pageSizeOptions,
          pageSize: filters?.pageSize,
          current: filters?.pageNumber,
          total: pagination?.totalCount,
          onChange: (page: number, pageSize: number) => {
            setFilters((filter: any) => ({
              ...filter,
              pageNumber: page,
              pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default CurrencyRateList;
