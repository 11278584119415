import { DatePicker, Form, InputNumber, Select, Space } from "antd";
import { Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import moment, { Moment } from "moment";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_QUERY_FORMAT, END_POINTS } from "../../../common/constants";
import BackButton from "../../../components/buttons/back";
import SaveButton from "../../../components/buttons/save";
import useFetchData from "../../../hooks/use-fetch-data";
import useHeader from "../../../hooks/use-header";
import useLoading from "../../../hooks/use-loading";
import i18n from "../../../i18n";
import { mutate } from "../../../utils/helper";

const { Item } = Form;
const LIST_CURRENCY_RATE_PAGE = "/currency-rate";
interface ICurrencyRateForm extends Omit<ICurrencyRate, "validityDate"> {
  validityDate: Moment;
}

const CurrencyRateForm: FC<{ init?: ICurrencyRate }> = ({ init }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm<ICurrencyRateForm>();
  const navigate = useNavigate();

  const { resource: currencies, fetching: fetchingCurrency } = useFetchData<
    ICurrency[]
  >({
    url: END_POINTS.CURRENCY.LIST,
    method: "get",
  });

  const header = useHeader();
  const loading = useLoading();

  let formInit: ICurrencyRateForm | undefined = undefined;

  if (init) {
    formInit = {
      ...init,
      validityDate: moment(init.validityDate),
    };
  }

  const onFinish = (values: ICurrencyRateForm) => {
    const submitValues = {
      ...values,
      id: id && parseInt(id) && init ? parseInt(id) : 0,
      validityDate: moment(values.validityDate).format(DATE_QUERY_FORMAT),
    };
    loading.show();
    mutate({
      config: {
        url: END_POINTS.CURRENCY_RATE.MODIFY,
        method: "post",
        data: submitValues,
      },
      onSuccess: () => {
        navigate(LIST_CURRENCY_RATE_PAGE);
      },
      onFinish: () => {
        loading.hide();
      },
    });
  };

  const currencyOptions: IOption[] = currencies
    ? currencies.map(c => ({ label: c.currencyCode, value: c.currencyId }))
    : [];

  useEffect(() => {
    const extra = (
      <Space size="small">
        <BackButton
          onClick={() => {
            navigate(LIST_CURRENCY_RATE_PAGE);
          }}
        />
        <SaveButton onClick={() => form.submit()} />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [id, header, form, navigate]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce(
        (arr: NamePath[], field) => (
          field.errors.length && arr.push(field.name), arr
        ),
        []
      );

    form.validateFields(errorFields);
  }, [i18n.language, form]);

  const rules: IRules = {
    currencyId: [
      {
        required: true,
        message: t("Currency.From currency is required") as string,
      },
    ],
    toCurrencyId: [
      {
        required: true,
        message: t("Currency.To currency is required") as string,
      },
    ],
    rate: [
      { required: true, message: t("Currency.Rating is required") as string },
    ],
    validityDate: [
      {
        required: true,
        message: t("Currency.Validity date is required") as string,
      },
    ],
  };

  return (
    <Form
      form={form}
      initialValues={formInit}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      labelWrap
    >
      <div className="lg:flex lg:gap-[16%]">
        <div className="lg:flex-1">
          <Item
            name="currencyId"
            label={t("Currency.From Currency")}
            rules={rules.currencyId}
          >
            <Select
              placeholder={t("Currency.Select currency")}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={fetchingCurrency}
              options={currencyOptions}
            />
          </Item>
          <Item
            name="rate"
            label={t("Currency.Rate")}
            rules={rules.rate}
          >
            <InputNumber
              className="w-full"
              step={0.01}
            />
          </Item>
        </div>
        <div className="lg:flex-1">
          <Item
            name="toCurrencyId"
            label={t("Currency.To Currency")}
            rules={rules.toCurrencyId}
          >
            <Select
              placeholder={t("Currency.Select currency")}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={fetchingCurrency}
              options={currencyOptions}
            />
          </Item>
          <Item
            name="validityDate"
            label={t("Currency.Validity Date")}
            rules={rules.validityDate}
          >
            <DatePicker
              className="w-full"
              placeholder={t("Select date") as string}
            />
          </Item>
        </div>
      </div>
    </Form>
  );
};

export default CurrencyRateForm;
