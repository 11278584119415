import { DownOutlined, KeyOutlined } from "@ant-design/icons";
import { Avatar, Divider, Dropdown, MenuProps, Space, Typography } from "antd";
import { FC, PropsWithChildren, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageToggle from "../../../components/languageToggle";
import { AppContext } from "../../../contexts";
import { logOut } from "../../../utils/helper";

const { Text } = Typography;

const UserMenuItem: FC<PropsWithChildren> = ({ children }) => (
  <div className="text-center py-1 min-w-[160px] flex items-center">
    {children}
  </div>
);

const HeaderInfo = () => {
  const { t } = useTranslation();
  const store = useContext(AppContext);
  const { user } = store;

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <UserMenuItem>
          <i className="fa-solid fa-user"></i>
          <div className="flex-1">
            <span>{user?.fullName}</span>
          </div>
        </UserMenuItem>
      ),
    },
    {
      key: "1",
      label: (
        <UserMenuItem>
          <Divider className="my-0" />
        </UserMenuItem>
      ),
    },
    {
      key: "3",
      label: (
        <UserMenuItem>
          <i className="fa-solid fa-gear"></i>
          <div className="flex-1">
            <Link to="/user-settings">{t("Settings")}</Link>
          </div>
        </UserMenuItem>
      ),
    },
    {
      key: "4",
      label: (
        <UserMenuItem>
          <i className="fa-solid fa-key"></i>
          <div className="flex-1">
            <Link to="/user/change-password">{t("Change Password")}</Link>
          </div>
        </UserMenuItem>
      ),
    },
    {
      key: "5",
      label: (
        <UserMenuItem>
          <i className="fa-solid fa-right-from-bracket"></i>
          <div className="flex-1">
            <div onClick={logOut}>{t("Logout")}</div>
          </div>
        </UserMenuItem>
      ),
    },
  ];

  return (
    <div>
      <Space
        size="small"
      >
        <Text className="text-white font-medium">
          <i className="fa-solid fa-database mr-2"></i>
          {user?.dbName}
        </Text>
        <LanguageToggle />
        <Dropdown
          menu={{ items }}
          placement="topRight"
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
        >
          <div className="flex gap-2 items-center cursor-pointer">
            <Avatar
              icon={<i className="fa-solid fa-user"></i>}
              className="cursor-pointer"
            />
            <span className="text-white font-medium">{user?.fullName}</span>
            <DownOutlined className="text-white" />
          </div>
        </Dropdown>
      </Space>
    </div>
  );
};

export default HeaderInfo;
