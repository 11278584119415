import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";

const AppButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { children } = props;
  return (
    <Button
      {...props}
      className={`flex justify-center items-center px-8 py-7 ${props.className}`}
    >
      <div className="flex items-center gap-2">{children}</div>
    </Button>
  );
};

export default AppButton;
