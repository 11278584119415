import { Space } from "antd";
import moment, { Moment } from "moment";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_TIME_FORMAT, DEFAULT_CURRENCY } from "../../../common/constants";
import BackButton from "../../../components/buttons/back";
import Seperate from "../../../components/seperate";
import Header from "../../../components/transaction/header";
import Item from "../../../components/transaction/input";
import useHeader from "../../../hooks/use-header";

const TRANSACTION_LIST_PAGE = "/transaction";

interface ITransactionForm extends Omit<ITransaction, "createdDate"> {
  createdDate: Moment;
}

interface IDetail {
  label: string;
  value?: string | number | null;
}
const TransactionForm: FC<{ init: ITransaction }> = ({ init }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const header = useHeader();

  const formInit: ITransactionForm = {
    ...init,
    createdDate: moment(init.createdDate),
  };

  useEffect(() => {
    const extra = (
      <Space size="small">
        <BackButton
          onClick={() => {
            navigate(TRANSACTION_LIST_PAGE);
          }}
        />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [id]);

  const {
    idGuid,
    title,
    serviceName,
    nidNipt,
    idTransaksioni,
    vleraSherbimitNeLeke,
    pershkrimeTeSherbimit,
    gjeneraliteteTePersonit,
    detajeTeTjera,
    returnUrlOk,
    returnUrlFailed,
    returnUrlAlways,
    clientIp,
    idTransactionFirsdata,
    base64TransactionId,
    paymentStatus,
    currencyCode,
    paymentCurrencyCode,
    exchangeRate,
    originalAmount,
    paymentFee,
    resultCodeFirstData,
    resultCode,
    s3dSec,
    rrn,
    approvalCode,
    cardNumber,
    sherbimiLejuar,
    language,
    treasury,
    statusiClerimitEalbania,
    createdDate,
    createdByUser,
    modifiedDate,
    modifiedByUser,
  } = formInit;

  let personalData: IOption[] = [];
  try {
    const parsed = JSON.parse(gjeneraliteteTePersonit);
    personalData = parsed;
  } catch (error) {}

  let serviceDetail: IOption[] = [];
  try {
    const parsed = JSON.parse(pershkrimeTeSherbimit);
    serviceDetail = parsed;
  } catch (error) {}

  let serviceOtherDetail: IDetail[] = [];
  try {
    const parsed: IDetail[] = JSON.parse(detajeTeTjera);
    serviceOtherDetail = parsed.filter(
      s => ![null, undefined, ""].some(i => i === s.value)
    );
  } catch (error) {}

  let treasuryParsed = [];
  try {
    const parsed = JSON.parse(treasury);
    treasuryParsed = parsed;
  } catch (error) {}

  let resultCodeFirstDataParsed: any;
  try {
    const parsed = JSON.parse(resultCodeFirstData);
    resultCodeFirstDataParsed = parsed;
  } catch (error) {}
  return (
    <div>
      <Header
        title={t("Transaction.Id Guid")}
        value={idGuid}
      />
      <Seperate />
      <Header
        title={t("Transaction.Base64 Transaction Id")}
        value={base64TransactionId}
      />
      <Seperate />
      <Header
        title={t("Transaction.Title")}
        value={title}
      />
      <Seperate />
      <Header
        title={t("Transaction.Language")}
        value={language}
      />
      <Seperate />
      <Header
        title={t("Transaction.Card Number")}
        value={cardNumber}
      />
      <Seperate />
      <Header
        title={t("Transaction.Payment Status")}
        value={paymentStatus}
      />
      <Seperate />

      <Header
        title={t("Transaction.Approval Code")}
        value={approvalCode}
      />
      <Seperate />

      <Header
        title={t("Transaction.Service")}
        value={serviceName}
      />
      <Item
        title={t("Transaction.VAT/PO")}
        value={nidNipt}
      />
      <Item
        title={t("Transaction.Invoice Ref")}
        value={idTransaksioni}
      />
      <Seperate />
      <Header title={t("Transaction.Personal Data")} />
      {personalData.map((p, idx) => (
        <Item
          key={idx}
          title={t(`paySummary.${p.label}`) || p.label}
          value={p.value}
        />
      ))}
      <Seperate />
      <Header title={t("Transaction.Service details")} />
      {serviceDetail.map((s, idx) => (
        <Item
          key={idx}
          title={t(`paySummary.${s.label}`) || s.label}
          value={s.value}
        />
      ))}
      <Seperate />
      {serviceOtherDetail.length > 0 && (
        <>
          <Header title={t("Transaction.Service other details")} />
          {serviceOtherDetail.map((s, idx) => (
            <Item
              key={idx}
              title={t(`paySummary.${s.label}`) || s.label}
              value={s.value}
            />
          ))}
          <Seperate />
        </>
      )}
      {treasuryParsed.length > 0 && (
        <>
          <Header title={t("Transaction.Treasury")} />
          {treasuryParsed.map((e: any) =>
            Object.keys(e).map(key => {
              return (
                <Item
                  key={key}
                  title={key}
                  value={e[key]}
                />
              );
            })
          )}
          <Seperate />
        </>
      )}
      <Header
        title={t("Transaction.Return Url Ok")}
        value={returnUrlOk}
      />
      <Seperate />
      <Header
        title={t("Transaction.Return Url Failed")}
        value={returnUrlFailed}
      />
      <Seperate />
      <Header
        title={t("Transaction.Return Url Always")}
        value={returnUrlAlways}
      />
      <Seperate />
      <Header
        title={t("Transaction.Client IP")}
        value={clientIp}
      />
      <Seperate />
      <Header
        title={t("Transaction.Id Transaction First Data")}
        value={idTransactionFirsdata}
      />
      <Seperate />
      {resultCodeFirstDataParsed && (
        <>
          <Header title={t("Transaction.Result Code First Data")} />
          {Object.keys(resultCodeFirstDataParsed).map(key => {
            return (
              <Item
                key={key}
                title={key}
                value={resultCodeFirstDataParsed[key]}
              />
            );
          })}
          <Seperate />
        </>
      )}
      <Seperate />
      <Header
        title={t("Transaction.Result Code")}
        value={resultCode}
      />
      <Seperate />
      <Header
        title={t("Transaction.S3dSec")}
        value={s3dSec}
      />
      <Seperate />
      <Header
        title={t("Transaction.RRN")}
        value={rrn}
      />
      <Seperate />
      <Header
        title={t("Transaction.Allowed Service")}
        value={sherbimiLejuar}
      />
      <Seperate />
      <Header
        title={t("Transaction.Clearing Status e-Albania")}
        value={statusiClerimitEalbania}
      />
      <Seperate />
      <Header
        title={t("Transaction.Created Date")}
        value={moment(createdDate).format(DATE_TIME_FORMAT)}
      />
      <Seperate />
      <Header
        title={t("Transaction.Created By")}
        value={createdByUser}
      />
      <Seperate />
      {modifiedDate && (
        <>
          <Header
            title={t("Transaction.Modified Date")}
            value={moment(modifiedDate).format(DATE_TIME_FORMAT)}
          />
          <Seperate />
        </>
      )}
      {modifiedByUser && (
        <>
          <Header
            title={t("Transaction.Modified By")}
            value={modifiedByUser}
          />
          <Seperate />
        </>
      )}
      <Header
        title={t("Transaction.Service amount")}
        value={`${originalAmount} ${currencyCode}`}
      />
      <Seperate />
      <Header
        title={t("Transaction.Payment fee")}
        value={`${paymentFee} ${paymentCurrencyCode}`}
      />
      <Seperate />
      {currencyCode &&
        currencyCode.toLowerCase() !== DEFAULT_CURRENCY.toLowerCase() && (
          <>
            <Header
              title={t("Transaction.Exchange rate")}
              value={exchangeRate}
            />
            <Seperate />
          </>
        )}
      <Header
        title={t("Transaction.Total amount")}
        value={`${vleraSherbimitNeLeke} ${paymentCurrencyCode}`}
      />
      <Seperate />
    </div>
  );
};
export default TransactionForm;
