import { RollbackOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import AppButton from ".";

const BackButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { t } = useTranslation();

  return (
    <AppButton {...props}>
      <RollbackOutlined /> {props.children || t("Back")}
    </AppButton>
  );
};

export default BackButton;
